import { createContext, useContext } from "react"
import { useNavigate } from "react-router-dom";

const LoginContext = createContext();

export const LoginContextProvider = ({ children }) => {
    const navigation = useNavigate();

    return (
        <LoginContext.Provider value={{ navigation }}>
            {children}
        </LoginContext.Provider>
    );
}

export const UseLoginContext = () => {
    return useContext(LoginContext);
}