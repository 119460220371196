import { Button, Content } from "../../../components/landing";
import { UsePricingContext } from "../../../contexts/landing/pricing/PricingContext";

const PricingPage = () => {
    const pricingContext = UsePricingContext();

    return (
        <Content is_active="pricing">
            <div className="py-10">
                <div className="text-center flex flex-col">
                    <h1 className="text-lg font-bold">Pilih paket yang sesuai dengan kebutuhan Anda</h1>
                    <h6>Kami sediakan paket sewa sesuai dengan kebutuhan anda, jika butuh konsultasi bisa langsung hubungi kami.</h6>
                </div>
                <div className="mt-5 md:mt-10">
                    <div className="flex overflow-x-auto scrollbar-hidden md:flex-wrap md:justify-center gap-2">
                        {pricingContext.category.map((item, index) => {
                            return (
                                <Button key={index} className={`border ${item.is_active && 'border-blue-500 text-blue-600'}`} onClick={() => pricingContext.onTabSwitch(index)}>{item.title}</Button>
                            );
                        })}
                    </div>
                </div>
                <div className="mt-5">
                    <div className="flex flex-wrap justify-between items-center gap-2 border border-blue-500 bg-blue-50 rounded-lg py-2 px-3">
                        <h4 className="font-bold md:text-base text-blue-600">Kurang Puas Dengan Fitur</h4>
                        <Button className="bg-blue-500 text-white">Hubungi Admin</Button>
                    </div>
                </div>
                <div className="mt-2">
                    <div className="flex flex-wrap justify-between border rounded-lg p-3">
                        <div className="flex flex-col">
                            <h4 className="font-bold text-base">Perhitungan Rencana</h4>
                            <small>Silakan hubungi kami jika ada yang tidak dimengerti</small>
                        </div>
                        <div className="flex flex-col md:flex-row mt-3 md:mt-0 gap-2">
                            <div className="flex flex-col">
                                <div className="px-1">
                                    <small className="font-semibold">Domain/Alamat Website</small>
                                </div>
                                <div className="border rounded-lg p-1 flex">
                                    {pricingContext.domainChoice.map((item, index) => {
                                        return (
                                            <Button key={index} className={`${item.is_active && 'bg-blue-500 text-white'}`} onClick={() => pricingContext.onTabSwitchDomain(index)}>{item.title}</Button>
                                        );
                                    })}
                                </div>
                            </div>
                            <div className="flex flex-col">
                                <div className="px-1">
                                    <small className="font-semibold">Zona Pembayaran</small>
                                </div>
                                <div className="border rounded-lg p-1 flex">
                                    {pricingContext.categoryCurrent?.payment_method?.map((item, index) => {
                                        return (
                                            <Button key={index} className={`${item.is_active && 'bg-blue-500 text-white'}`} onClick={() => pricingContext.onTabSwitchPayment(index)}>{item.title}</Button>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mt-2">
                    <div className="border rounded-lg p-3">
                        <div className="flex flex-wrap justify-between">
                            <div className="flex flex-col">
                                <h4 className="font-bold text-base">Harga dan Fitur</h4>
                                <small>Silakan hubungi kami jika ada yang tidak dimengerti</small>
                            </div>
                            <div className="flex flex-row items-center gap-4 md:gap-0 md:items-end md:flex-col justify-end grow md:justify-start md:grow-0">
                                <div className="flex justify-end">
                                    <small className="font-semibold">{`Harga => Bulanan`}</small>
                                </div>
                                <div className="leading-3 mt-1">
                                    <div className="flex justify-end items-end gap-1">
                                        <span className="font-bold text-2xl">
                                            Rp250.000
                                        </span>
                                    </div>
                                    <div className="flex justify-end">
                                        <small className="font-bold text-orange-600">/Bulan</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr className="my-2" />
                        <div className="mt-2">
                            <h4 className="font-bold text-base">Yang didapatkan</h4>
                            <div dangerouslySetInnerHTML={{ __html: '' }} />
                            <div className="mt-4">
                                {pricingContext.subscription?.subscription_detail?.map((item, index) => {
                                    return (
                                        <div key={index} className="flex items-center gap-2">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="text-blue-600" width="16" height="16" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" /><path d="M9 12l2 2l4 -4" /></svg>
                                            <span className="text-medium">{item.title}</span>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                        <div className="mt-4">
                            <div className="flex gap-2">
                                <Button className={`border border-blue-500 text-blue-600`} onClick={() => { }}>Konsultasi</Button>
                                <Button className={`bg-blue-500 text-white`} onClick={() => { }}>Mulai Sekarang</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Content>
    );
}

export default PricingPage;