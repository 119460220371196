import { useNavigate } from "react-router-dom";
import { logo } from "../../../../assets";
import { Button } from "../../atoms";

const Sidebar = (props) => {
    const navigation = useNavigate();

    return (
        <div className="fixed top-0 bottom-0 left-0 right-0 z-40 bg-white">
            <div className="flex justify-between items-center py-3 px-5 border-b">
                <div className="flex items-center gap-1">
                    <img src={logo} alt="logoPTRelasiSymbioSolusi" className="w-7 p-1" />
                    <div className="flex gap-1 leading-3">
                        <span className="font-semibold text-[12px] whitespace-pre">PT Relasi</span>
                        <span className="font-semibold text-[12px]">Symbio</span>
                        <span className="font-semibold text-[12px]">Solusi</span>
                    </div>
                </div>
                <div>
                    <div className="p-1 rounded-full bg-red-50" onClick={() => props.onClose && props.onClose()}>
                        <svg xmlns="http://www.w3.org/2000/svg" className="font-bold text-red-500" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M18 6l-12 12" /><path d="M6 6l12 12" /></svg>
                    </div>
                </div>
            </div>
            <div className="mt-5 px-5">
                <div className="flex gap-3">
                    <Button className="border border-blue-500" onClick={() => navigation('/login')}>Masuk</Button>
                    <Button className="bg-blue-500 text-white">Mulai Sekarang</Button>
                </div>
            </div>
            <div className="border border-y py-[1px] bg-blue-100 mt-5" />
            <div className="">
                <ul>
                    <li className="py-3 px-5 border-b" onClick={() => navigation('/')}>
                        <span className="font-semibold">Home</span>
                    </li>
                    <li className="py-3 px-5 border-b" onClick={() => navigation('/pricing')}>
                        <span className="font-semibold">Harga</span>
                    </li>
                    <li className="py-3 px-5 border-b">
                        <span className="font-semibold">Bantuan</span>
                    </li>
                </ul>
            </div>
        </div>
    );
}

export default Sidebar;