import { createContext, useContext, useEffect } from "react"
import { useNavigate } from "react-router-dom";

const DetailContext = createContext();

export const DetailContextProvider = ({ children }) => {
    const navigation = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <DetailContext.Provider value={{ navigation }}>
            {children}
        </DetailContext.Provider>
    );
}

export const UseDetailContext = () => {
    return useContext(DetailContext);
}