import Footer from "../footer/Footer";
import Navbar from "../navbar/Navbar";

const Content = (props) => {
    return (
        <div className="text-[12px] md:text-[13px] flex flex-col md:min-h-screen">
            {props.navbar !== false && (<Navbar is_active={props.is_active} />)}
            {props.tron && props.tron}
            <div className="grow relative flex justify-center">
                <div className={`${props.padding !== false && 'px-3'} pb-16 relative w-full md:w-[95%] max-w-[80rem]`}>
                    {props.children}
                </div>
            </div>
            {/* Button Help */}
            <div className="fixed bottom-5 md:bottom-10 right-5 md:right-20 z-50">
                <div className="px-3 py-2 rounded-full shadow-all bg-white flex gap-2 items-center">
                    <div className="relative flex justify-center items-center">
                        <span className="absolute flex h-6 w-6">
                            <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-sky-400 opacity-75"></span>
                        </span>
                        <svg xmlns="http://www.w3.org/2000/svg" className="text-blue-500" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M4 14v-3a8 8 0 1 1 16 0v3" /><path d="M18 19c0 1.657 -2.686 3 -6 3" /><path d="M4 14a2 2 0 0 1 2 -2h1a2 2 0 0 1 2 2v3a2 2 0 0 1 -2 2h-1a2 2 0 0 1 -2 -2v-3z" /><path d="M15 14a2 2 0 0 1 2 -2h1a2 2 0 0 1 2 2v3a2 2 0 0 1 -2 2h-1a2 2 0 0 1 -2 -2v-3z" /></svg>
                    </div>
                    <span className="font-bold text-blue-500">Bantuan</span>
                </div>
            </div>
            {props.footer !== false && (<Footer />)}
        </div>
    );
}

export default Content;