import { createContext, useContext, useEffect } from "react"
import { useNavigate } from "react-router-dom";

const MainContext = createContext();

export const MainContextProvider = ({ children }) => {
    const navigation = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <MainContext.Provider value={{ navigation }}>
            {children}
        </MainContext.Provider>
    );
}

export const UseMainContext = () => {
    return useContext(MainContext);
}