import { createContext, useContext, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom";

const PricingContext = createContext();

export const PricingContextProvider = ({ children }) => {
    const navigation = useNavigate();
    const [categoryCurrent, setCategoryCurrent] = useState({});
    const [category, setCategory] = useState([
        {
            title: 'E-Commerce',
            is_active: false,
            payment_method: [
                {
                    title: 'Persentase',
                    is_active: false,
                },
                {
                    title: 'Bulanan',
                    is_active: true,
                },
            ],
        },
        {
            title: 'Web Promotion',
            is_active: false,
            payment_method: [
                {
                    title: 'Bulanan',
                    is_active: false,
                },
                {
                    title: 'Selamanya',
                    is_active: true,
                },
            ],
        },
        {
            title: 'Company Profile',
            is_active: false,
            payment_method: [
                {
                    title: 'Bulanan',
                    is_active: true,
                },
                {
                    title: 'Selamanya',
                    is_active: false,
                },
            ],
        },
        {
            title: 'Portofolio',
            is_active: false,
            payment_method: [
                {
                    title: 'Harian',
                    is_active: true,
                },
                {
                    title: 'Bulanan',
                    is_active: false,
                },
                {
                    title: 'Selamanya',
                    is_active: false,
                },
            ],
        },
    ]);
    const [domainChoice, setDomainChoice] = useState([
        {
            title: 'Gratis',
            is_active: true,
        },
        {
            title: 'Custome',
            is_active: false,
        },
    ]);

    const [subscription, setSubscription] = useState({
        subscription_detail: [
            {
                title: 'Premium',
            },
            {
                title: 'Sistem chat wa',
            },
            {
                title: 'Sistem keranjang',
            },
            {
                title: 'Buat produk sepuasnya',
            },
        ],
    });

    const onTabSwitch = (indexItem) => {
        category.forEach((item, index) => {
            category[index].is_active = false;
        });

        category[indexItem].is_active = true;
        setCategory([...category]);
        setCategoryCurrent({ ...category[indexItem] });
    }

    const onTabSwitchDomain = (indexItem) => {
        domainChoice.forEach((item, index) => {
            domainChoice[index].is_active = false;
        });

        domainChoice[indexItem].is_active = true;
        setDomainChoice([...domainChoice]);
    }

    const onTabSwitchPayment = (indexItem) => {
        categoryCurrent.payment_method.forEach((item, index) => {
            categoryCurrent.payment_method[index].is_active = false;
        });

        categoryCurrent.payment_method[indexItem].is_active = true;
        setCategoryCurrent({ ...categoryCurrent });
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        onTabSwitch(0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <PricingContext.Provider value={{ navigation, category, categoryCurrent, onTabSwitch, domainChoice, onTabSwitchDomain, onTabSwitchPayment, subscription, setSubscription }}>
            {children}
        </PricingContext.Provider>
    );
}

export const UsePricingContext = () => {
    return useContext(PricingContext);
}