import { Route, Routes } from 'react-router-dom';
import { LoginContextProvider } from './contexts/auth/login/LoginContext';
import { RegisterContextProvider } from './contexts/auth/register/RegisterContext';
import { DetailContextProvider } from './contexts/landing/detail/DetailContext';
import { MainContextProvider } from './contexts/landing/home/MainContext';
import { PricingContextProvider } from './contexts/landing/pricing/PricingContext';
import { LoginPage, RegisterPage } from './pages/auth';
import { BoardDashboardPage } from './pages/board';
import { DetailPage, MainPage, PricingPage } from './pages/landing';

function App() {
  return (
    <Routes>
      <Route path="/" element={
        <MainContextProvider>
          <MainPage />
        </MainContextProvider>
      } />
      <Route path="/pricing" element={
        <PricingContextProvider>
          <PricingPage />
        </PricingContextProvider>
      } />
      <Route path="/detail" element={
        <DetailContextProvider>
          <DetailPage />
        </DetailContextProvider>
      } />
      {/* Authentication */}
      <Route path="/login" element={
        <LoginContextProvider>
          <LoginPage />
        </LoginContextProvider>
      } />
      <Route path="/register" element={
        <RegisterContextProvider>
          <RegisterPage />
        </RegisterContextProvider>
      } />
      {/* Dashboard */}
      <Route path="/board" element={
        <BoardDashboardPage />
      } />
    </Routes>
  );
}

export default App;
