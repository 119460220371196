import { ilComputer } from "../../../../assets";
import { Button } from "../../../../components/landing";

const Tron = () => {
    return (
        <div className="bg-white">
            <div className="flex justify-center">
                <div className="w-full md:w-[95%] max-w-[80rem] px-5 pt-8 md:pt-20 pb-8 md:pb-36 relative overflow-hidden">
                    {/* Decoration */}
                    <div className="absolute -top-10 left-0 h-[5rem] w-[5rem] bg-blue-400 rounded-full hidden md:block" />
                    <div className="absolute -bottom-10 right-0 h-[5rem] w-[5rem] bg-blue-400 rounded-full hidden md:block" />
                    <div className="absolute top-10 right-10 h-[23rem] w-[23rem] bg-blue-50 md:bg-blue-100 rounded-full" />
                    <div className="absolute top-10 right-[30rem] h-[5rem] w-[5rem] bg-teal-500 rounded-full hidden md:block" />
                    <div className="absolute bottom-20 left-72 h-[3rem] w-[3rem] bg-teal-500 rounded-full hidden md:block" />
                    <img className="absolute top-0 -right-7 w-[37rem] hidden md:block" src={ilComputer} alt="overlayBg" />
                    <div className="relative">
                        <div className="leading-6 md:leading-none text-xl md:text-4xl">
                            <h1 className="font-semibold mt-1">Ciptakan Peluang <span className="text-blue-500 font-bold">Usaha</span></h1>
                            <h1 className="font-semibold mt-1"> Optimalkan Pengalaman</h1>
                            <h1 className="text-blue-500 font-bold"> Bisnismu</h1>
                        </div>
                        <div className="mt-3 md:flex md:flex-col">
                            <span>Perluas jangkawan bisnis anda dengan platform digital</span>
                            <span>Mulai kerjasama dalam pengembangan usaha yang lebih maju</span>
                        </div>
                        <div className="mt-8 md:mt-16 flex">
                            <Button className="bg-blue-500 text-white py-[0.6rem]">Mulai Sekarang</Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Tron;