import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { logo } from "../../../../assets";
import { Button } from "../../atoms";
import Sidebar from "../sidebar/Sidebar";

const Navbar = (props) => {
    const navigation = useNavigate();
    const [element, setElement] = useState(null);
    const nav = [
        {
            title: 'Home',
            is_active: props.is_active === 'home' ? true : false,
            on_click: () => navigation('/'),
        },
        {
            title: 'Harga',
            is_active: props.is_active === 'pricing' ? true : false,
            on_click: () => navigation('/pricing'),
        },
    ];

    return (
        <>
            <nav className="shadow-md z-20 bg-white sticky top-0 hidden md:block">
                <div className="bg-slate-200 py-1 px-5 flex justify-center">
                    <div className="w-full md:w-[95%] max-w-[80rem]">
                        <span className="font-medium text-slate-600">Created By PT Relasi Symbio Solusi</span>
                    </div>
                </div>
                <div className="px-5 flex justify-center">
                    <div className="w-full md:w-[95%] max-w-[80rem]">
                        <div className="flex justify-between gap-5 items-center">
                            <div className="grow flex items-center gap-5">
                                <div className="flex items-center gap-2">
                                    <img src={logo} alt="logoPTRelasiSymbioSolusi" className="w-10 p-1" />
                                    <div className="flex flex-col leading-3">
                                        <span className="font-medium text-[10px]">PT Relasi</span>
                                        <span className="font-medium text-[10px]">Symbio</span>
                                        <span className="font-medium text-[10px]">Solusi</span>
                                    </div>
                                </div>
                                <div className="px-5 flex justify-center">
                                    <ul className="flex items-center">
                                        {nav.map((item, index) => {
                                            return (
                                                <li key={index} className={`cursor-pointer hover:text-slate-800 font-semibold py-4 px-4 ${item.is_active ? 'border-b-2 border-blue-500 text-slate-800' : 'text-slate-500'}`} onClick={() => item.on_click && item.on_click()}>{item.title}</li>
                                            );
                                        })}
                                    </ul>
                                </div>
                            </div>
                            <div className="flex items-center gap-2">
                                <div className="mr-3" onClick={() => navigation('/login')}>
                                    <span className="font-semibold cursor-pointer text-slate-800">Bantuan</span>
                                </div>
                                <div className="mr-3" onClick={() => navigation('/login')}>
                                    <span className="font-semibold cursor-pointer text-slate-800">Masuk</span>
                                </div>
                                <Button className="border border-blue-600 bg-blue-600" onClick={() => navigation('/pricing')}>
                                    <span className="font-semibold cursor-pointer text-white">Mulai Sekarang</span>
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
            <nav className="shadow-md z-20 bg-white sticky top-0 block md:hidden">
                <div className="bg-slate-200 py-1 px-2 flex justify-center">
                    <div className="w-full md:w-[95%] max-w-[80rem]">
                        <span className="font-medium text-slate-600 text-[12px]">Created By PT Relasi Symbio Solusi</span>
                    </div>
                </div>
                <div className="px-2 flex justify-center">
                    <div className="w-full md:w-[95%] max-w-[80rem]">
                        <div className="flex justify-between gap-5 items-center">
                            <div className="flex items-center gap-1">
                                <img src={logo} alt="logoPTRelasiSymbioSolusi" className="w-10 p-1" />
                                <div className="flex gap-1 leading-3">
                                    <span className="font-semibold text-[12px] whitespace-pre">PT Relasi</span>
                                    <span className="font-semibold text-[12px]">Symbio</span>
                                    <span className="font-semibold text-[12px]">Solusi</span>
                                </div>
                            </div>
                            <div>
                                <span className="cursor-pointer" onClick={() => setElement(<Sidebar onClose={() => setElement(null)} />)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M4 6h16" /><path d="M7 12h13" /><path d="M10 18h10" /></svg>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
            {element}
        </>
    );
}

export default Navbar;