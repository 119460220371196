const Footer = () => {
    const menu = [
        {
            title: 'Home',
        },
        {
            title: 'Product',
        },
        {
            title: 'Category',
        },
        {
            title: 'Message Box',
        },
        {
            title: 'Shopping Cart',
        },
    ];
    const contact = [
        {
            title: 'Email',
            value: 'ptrelasisymbiosolusi@gmail.com',
        },
        {
            title: 'Phone',
            value: '(+62) 8888 - 507979',
        },
    ];
    return (
        <footer className="shadow-all flex justify-center">
            <div className="w-full md:w-[95%] max-w-[80rem] px-3">
                <div className="flex flex-col md:flex-row gap-2 md:gap-0 py-5 md:py-10">
                    <div className="grow">
                        <span className="font-bold text-xl">PT Relasi Symbio Solusi</span>
                        <p className="mt-2">
                            Law marvelous deceptions spirit sexuality value zarathustra christianity. Snare philosophy hatred intentions burying chaos endless derive burying free chaos ideal gains. Law marvelous deceptions spirit sexuality value zarathustra christianity. Snare philosophy hatred intentions burying chaos endless derive burying free chaos ideal gains.
                        </p>
                    </div>
                    <div className="flex justify-center items-center mx-5">
                        <div className="h-[100%] bg-slate-300 w-[1px]" />
                    </div>
                    <div className="w-80">
                        <span className="font-bold text-xl">Menu</span>
                        <ul>
                            {menu.map((item, index) => {
                                return (
                                    <li key={index}>{item.title}</li>
                                );
                            })}
                        </ul>
                    </div>
                    <div className="flex justify-center items-center mx-5">
                        <div className="h-[100%] bg-slate-300 w-[1px]" />
                    </div>
                    <div className="w-80">
                        <span className="font-bold text-xl">Contact Us</span>
                        <ul>
                            {contact.map((item, index) => {
                                return (
                                    <li key={index}>{item.title}</li>
                                );
                            })}
                        </ul>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;