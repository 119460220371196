import { ilBgBanner, ilComputer } from "../../../../assets";
import { Button } from "../../../../components/landing";

const Tron = () => {
    return (
        <div className="bg-image" style={{ background: `url(${ilBgBanner})`, backgroundRepeat: 'no-repeat' }}>
            <div className="flex justify-center">
                <div className="w-full md:w-[95%] max-w-[80rem] px-5 pt-8 md:pt-20 pb-8 md:pb-36 relative overflow-hidden">
                    {/* Decoration */}
                    <div className="absolute -top-14 md:-top-10 left-0 h-[5rem] w-[5rem] bg-white rounded-full" />
                    <div className="absolute -top-10 right-0 h-[5rem] w-[5rem] bg-white rounded-full hidden md:block" />
                    <div className="absolute bottom-10 right-96 h-[3rem] w-[3rem] bg-blue-400 rounded-full hidden md:block" />
                    <div className="absolute top-14 right-10 h-[23rem] w-[23rem] bg-slate-100 rounded-full hidden md:block" />
                    <div className="absolute top-10 right-[30rem] h-[2rem] w-[2rem] bg-white rounded-full" />
                    <div className="absolute bottom-20 left-72 h-[1.5rem] w-[1.5rem] bg-white rounded-full" />
                    <img className="absolute top-10 -right-7 w-[37rem] hidden md:block" src={ilComputer} alt="overlayBg" />
                    <div className="relative text-white">
                        <div className="leading-6 md:leading-none text-xl md:text-4xl">
                            <h1 className="font-semibold mt-1">Realisasi Impian E-Commerce</h1>
                            <h1 className="font-semibold mt-1">Anda dalam Sehari! Lebih Mudah dari</h1>
                            <h1 className="font-semibold mt-1">yang Anda Bayangkan</h1>
                        </div>
                        <div className="mt-3 flex flex-col">
                            <span>Manfaatkan kontrol penuh atas setiap aspek</span>
                            <span>bisni Anda melalui platform terintegrasi yang inovatif.</span>
                        </div>
                        <div className="mt-8 md:mt-16 flex">
                            <Button className="bg-white text-black py-[0.6rem] border border-blue-500 md:border-none">Mulai Sekarang</Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Tron;