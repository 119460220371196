import { Content } from "../../../components/board";

const BoardDashboardPage = () => {
    return (
        <Content>
            Oke
        </Content>
    );
}

export default BoardDashboardPage;