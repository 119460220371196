import { Card, Content } from "../../../components/landing";
import { Tron } from "./component";

const DetailPage = () => {
    return (
        <Content tron={<Tron />}>
            <div className="py-10 md:py-20">
                <div className="grid md:grid-cols-4 gap-5">
                    <Card className="py-8">
                        <div className="flex justify-center">
                            <span className="bg-teal-50 p-3 rounded-full">
                                <svg xmlns="http://www.w3.org/2000/svg" className="text-teal-500" width="60" height="60" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M19.03 17.818a3 3 0 0 0 1.97 -2.818v-8a3 3 0 0 0 -3 -3h-12a3 3 0 0 0 -3 3v8c0 1.317 .85 2.436 2.03 2.84" /><path d="M10 14a2 2 0 1 0 4 0a2 2 0 0 0 -4 0" /><path d="M8 21a2 2 0 0 1 2 -2h4a2 2 0 0 1 2 2" /></svg>
                            </span>
                        </div>
                        <div className="flex flex-col text-center mt-3">
                            <h4 className="font-bold text-lg px-8">Fungsional Yang Unggul</h4>
                        </div>
                        <div className="flex justify-center mt-3">
                            <div className="h-1 w-20 bg-blue-500 rounded-full" />
                        </div>
                        <div className="mt-5 text-center px-5">
                            <span>Platform kami menyediakan fitur-fitur e-commerce yang canggih seperti keranjang belanja, manajemen inventaris yang membantu Anda menjalankan toko online dengan lancar.</span>
                        </div>
                    </Card>
                    <Card className="py-8">
                        <div className="flex justify-center">
                            <span className="bg-teal-50 p-3 rounded-full">
                                <svg xmlns="http://www.w3.org/2000/svg" className="text-teal-500" width="60" height="60" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M12 3c7.2 0 9 1.8 9 9s-1.8 9 -9 9s-9 -1.8 -9 -9s1.8 -9 9 -9z" /><path d="M8 11m0 1a1 1 0 0 1 1 -1h6a1 1 0 0 1 1 1v3a1 1 0 0 1 -1 1h-6a1 1 0 0 1 -1 -1z" /><path d="M10 11v-2a2 2 0 1 1 4 0v2" /></svg>
                            </span>
                        </div>
                        <div className="flex flex-col text-center mt-3">
                            <h4 className="font-bold text-lg px-8">Skalabilitas dan Keamanan</h4>
                        </div>
                        <div className="flex justify-center mt-3">
                            <div className="h-1 w-20 bg-blue-500 rounded-full" />
                        </div>
                        <div className="mt-5 text-center px-5">
                            <span>Platform kami menyediakan fitur-fitur e-commerce yang canggih seperti keranjang belanja, manajemen inventaris yang membantu Anda menjalankan toko online dengan lancar.</span>
                        </div>
                    </Card>
                    <Card className="py-8">
                        <div className="flex justify-center">
                            <span className="bg-teal-50 p-3 rounded-full">
                                <svg xmlns="http://www.w3.org/2000/svg" className="text-teal-500" width="60" height="60" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M18 4a3 3 0 0 1 3 3v8a3 3 0 0 1 -3 3h-5l-5 3v-3h-2a3 3 0 0 1 -3 -3v-8a3 3 0 0 1 3 -3h12z" /><path d="M9.5 9h.01" /><path d="M14.5 9h.01" /><path d="M9.5 13a3.5 3.5 0 0 0 5 0" /></svg>
                            </span>
                        </div>
                        <div className="flex flex-col text-center mt-3">
                            <h4 className="font-bold text-lg px-8">Dukungan Pelanggan yang Luar Biasa</h4>
                        </div>
                        <div className="flex justify-center mt-3">
                            <div className="h-1 w-20 bg-blue-500 rounded-full" />
                        </div>
                        <div className="mt-5 text-center px-5">
                            <span>Platform kami menyediakan fitur-fitur e-commerce yang canggih seperti keranjang belanja, manajemen inventaris yang membantu Anda menjalankan toko online dengan lancar.</span>
                        </div>
                    </Card>
                    <Card className="py-8">
                        <div className="flex justify-center">
                            <span className="bg-teal-50 p-3 rounded-full">
                                <svg xmlns="http://www.w3.org/2000/svg" className="text-teal-500" width="60" height="60" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M4 19l16 0" /><path d="M4 15l4 -6l4 2l4 -5l4 4" /></svg>
                            </span>
                        </div>
                        <div className="flex flex-col text-center mt-3">
                            <h4 className="font-bold text-lg px-8">Analitik yang Mendalam</h4>
                        </div>
                        <div className="flex justify-center mt-3">
                            <div className="h-1 w-20 bg-blue-500 rounded-full" />
                        </div>
                        <div className="mt-5 text-center px-5">
                            <span>Platform kami menyediakan fitur-fitur e-commerce yang canggih seperti keranjang belanja, manajemen inventaris yang membantu Anda menjalankan toko online dengan lancar.</span>
                        </div>
                    </Card>
                </div>
            </div>
            <div className="py-10 md:py-20 bg-slate-50 rounded-lg">
                <div className="text-center">
                    <h1 className="text-2xl font-semibold">Keuntungan</h1>
                    <div className="flex justify-center items-center mt-4 relative">
                        <div className="bg-blue-500 py-[0.15rem] px-20 rounded-full" />
                        <div className="absolute w-4 h-4 bg-blue-500 rounded-full" />
                    </div>
                </div>
                <div className="mt-10 md:mt-10 flex flex-wrap md:flex-nowrap md:justify-around gap-3 md:gap-10 px-5 md:px-14">
                    <ul>
                        <li className="py-3">
                            <div className="flex items-start gap-3">
                                <div className="min-h-3 max-h-3 h-3 min-w-3 max-w-3 w-3 bg-teal-500 rounded-full mt-2" />
                                <div className="flex flex-col gap-4">
                                    <h2 className="text-lg font-bold">Fleksibilitas Konten</h2>
                                    <p>Platform CMS kami memungkinkan Anda untuk dengan mudah mengelola konten situs web Anda, termasuk produk, gambar, deskripsi, dan konten lainnya, sehingga Anda dapat terus memperbarui dan menyegarkan situs web Anda sesuai kebutuhan.</p>
                                </div>
                            </div>
                        </li>
                        <li className="py-3">
                            <div className="flex items-start gap-3">
                                <div className="min-h-3 max-h-3 h-3 min-w-3 max-w-3 w-3 bg-teal-500 rounded-full mt-2" />
                                <div className="flex flex-col gap-4">
                                    <h2 className="text-lg font-bold">Integrasi yang Luas</h2>
                                    <p>Dengan platform CMS yang terintegrasi dengan berbagai layanan dan aplikasi pihak ketiga, Anda dapat dengan mudah mengintegrasikan alat dan layanan yang diperlukan, seperti sistem pembayaran, pengiriman, pemasaran, dan lainnya, untuk meningkatkan fungsionalitas dan kinerja situs web Anda.</p>
                                </div>
                            </div>
                        </li>
                        <li className="py-3">
                            <div className="flex items-start gap-3">
                                <div className="min-h-3 max-h-3 h-3 min-w-3 max-w-3 w-3 bg-teal-500 rounded-full mt-2" />
                                <div className="flex flex-col gap-4">
                                    <h2 className="text-lg font-bold">Personalisasi Pengalaman Pengguna</h2>
                                    <p>Dengan alat personalisasi yang disediakan oleh platform CMS kami, Anda dapat membuat pengalaman belanja yang lebih menarik dan relevan bagi pelanggan Anda dengan menyesuaikan tampilan dan penawaran berdasarkan preferensi dan perilaku mereka.</p>
                                </div>
                            </div>
                        </li>
                    </ul>
                    <ul>
                        <li className="py-3">
                            <div className="flex items-start gap-3">
                                <div className="min-h-3 max-h-3 h-3 min-w-3 max-w-3 w-3 bg-teal-500 rounded-full mt-2" />
                                <div className="flex flex-col gap-4">
                                    <h2 className="text-lg font-bold">Optimisasi SEO</h2>
                                    <p>Platform CMS kami dilengkapi dengan fitur SEO yang kuat yang memungkinkan Anda untuk mengoptimalkan situs web Anda agar mudah ditemukan oleh mesin pencari seperti Google, sehingga meningkatkan visibilitas dan lalu lintas organik ke situs web Anda.</p>
                                </div>
                            </div>
                        </li>
                        <li className="py-3">
                            <div className="flex items-start gap-3">
                                <div className="min-h-3 max-h-3 h-3 min-w-3 max-w-3 w-3 bg-teal-500 rounded-full mt-2" />
                                <div className="flex flex-col gap-4">
                                    <h2 className="text-lg font-bold">Pengelolaan Tim yang Efisien</h2>
                                    <p>Jika Anda bekerja dengan tim, platform CMS kami memungkinkan Anda untuk mengelola akses dan tugas secara terpusat, meningkatkan kolaborasi dan efisiensi dalam pengembangan dan pemeliharaan situs web Anda.</p>
                                </div>
                            </div>
                        </li>
                        <li className="py-3">
                            <div className="flex items-start gap-3">
                                <div className="min-h-3 max-h-3 h-3 min-w-3 max-w-3 w-3 bg-teal-500 rounded-full mt-2" />
                                <div className="flex flex-col gap-4">
                                    <h2 className="text-lg font-bold">Pembaruan dan Peningkatan Berkala</h2>
                                    <p>Platform CMS kami terus diperbarui dan ditingkatkan secara berkala dengan fitur-fitur baru dan perbaikan keamanan, sehingga Anda dapat yakin bahwa situs web Anda selalu berada di atas teknologi terbaru.</p>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </Content>
    );
}

export default DetailPage;