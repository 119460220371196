import { Button, Card, Content } from "../../../components/landing";
import { UseMainContext } from "../../../contexts/landing/home/MainContext";
import { Tron } from "./component";

const MainPage = () => {
    const { navigation } = UseMainContext();

    return (
        <Content tron={<Tron />} is_active="home">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-10 md:gap-4 py-10 md:py-20">
                <div className="flex items-start md:flex-col gap-3">
                    <div className="mb-3 flex">
                        <div className="bg-blue-50 rounded-full md:p-3">
                            <span>
                                <svg xmlns="http://www.w3.org/2000/svg" className="text-blue-500 scale-50 md:scale-100" width="60" height="60" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M12 21h-7a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v7" /><path d="M3 10h18" /><path d="M10 3v18" /><path d="M19.001 19m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" /><path d="M19.001 15.5v1.5" /><path d="M19.001 21v1.5" /><path d="M22.032 17.25l-1.299 .75" /><path d="M17.27 20l-1.3 .75" /><path d="M15.97 17.25l1.3 .75" /><path d="M20.733 20l1.3 .75" /></svg>
                            </span>
                        </div>
                    </div>
                    <div className="flex flex-col grow">
                        <span className="font-bold text-lg md:text-xl">Tampilan Sesuai Keinginan</span>
                        <p className="mt-3">
                            Tampilan lebih elagan dan dan sesuai keinginan mu, riset pasar kami akan kami berlakukan juga dalam tampilan anda untuk bisa menarik para customer anda
                        </p>
                        <div className="grow"></div>
                        <div className="flex justify-start mt-7">
                            <Button className="border border-blue-500 text-blue-500">Bantuan Admin</Button>
                        </div>
                    </div>
                </div>
                <div className="flex items-start md:flex-col gap-3">
                    <div className="mb-3 flex">
                        <div className="bg-blue-50 rounded-full md:p-3">
                            <span>
                                <svg xmlns="http://www.w3.org/2000/svg" className="text-blue-500 scale-50 md:scale-100" width="60" height="60" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M4 4m0 1a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v4a1 1 0 0 1 -1 1h-4a1 1 0 0 1 -1 -1z" /><path d="M14 4m0 1a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v4a1 1 0 0 1 -1 1h-4a1 1 0 0 1 -1 -1z" /><path d="M4 14m0 1a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v4a1 1 0 0 1 -1 1h-4a1 1 0 0 1 -1 -1z" /><path d="M14 17h6m-3 -3v6" /></svg>
                            </span>
                        </div>
                    </div>
                    <div className="flex flex-col grow">
                        <span className="font-bold text-lg md:text-xl">Bebas Tambah Produk</span>
                        <p className="mt-3">
                            Jual produk anda sebanyak banyak nya dan dapatkan keuntungan berkali kali lipat, Jangan khawatik lupa harga produk dan spesifikasi produk
                        </p>
                        <div className="grow"></div>
                        <div className="flex justify-start mt-7">
                            <Button className="border border-blue-500 text-blue-500">Bantuan Admin</Button>
                        </div>
                    </div>
                </div>
                <div className="flex items-start md:flex-col gap-3">
                    <div className="mb-3 flex">
                        <div className="bg-blue-50 rounded-full md:p-3">
                            <span>
                                <svg xmlns="http://www.w3.org/2000/svg" className="text-blue-500 scale-50 md:scale-100" width="60" height="60" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M17 10l-2 -6" /><path d="M7 10l2 -6" /><path d="M12 20h-4.756a3 3 0 0 1 -2.965 -2.544l-1.255 -7.152a2 2 0 0 1 1.977 -2.304h13.999a2 2 0 0 1 1.977 2.304l-.358 2.04" /><path d="M10 14a2 2 0 1 0 4 0a2 2 0 0 0 -4 0" /><path d="M19 22v-6" /><path d="M22 19l-3 -3l-3 3" /></svg>
                            </span>
                        </div>
                    </div>
                    <div className="flex flex-col grow">
                        <span className="font-bold text-lg md:text-xl">Mudah Bertransaksi</span>
                        <p className="mt-3">
                            Kami sediakan berbagai sistem transaksi, mulai dari langsung notifikasi WhatsApp hingga transaksi dalam aplikasi
                        </p>
                        <div className="grow"></div>
                        <div className="flex justify-start mt-7">
                            <Button className="border border-blue-500 text-blue-500">Bantuan Admin</Button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="grid gris-cols-1 md:grid-cols-5 gap-3 md:gap-8">
                <div className="md:col-span-2 flex flex-col">
                    <h1 className="text-2xl md:text-3xl font-bold text-blue-950 text-center md:text-start">Solusi kemudahan</h1>
                    <h1 className="text-2xl md:text-3xl font-bold text-blue-950 text-center md:text-start">untuk bisnismu</h1>
                    <Card className="rounded-xl px-5 md:px-8 py-8 bg-gradient-to-l from-cyan-500 to-blue-500 text-white grow mt-7 flex flex-col">
                        <h5 className="text-lg font-bold">PENJUALAN</h5>
                        <p className="mt-4">
                            Jelajahi koleksi produk kami dan tingkatkan penjualan produk Anda dan jangkau customer lebih luas!
                        </p>
                        <div className="grow"></div>
                        <div className="mt-3 flex gap-1 items-center">
                            <span className="text-base font-medium">Baca Selenkapnya</span>
                            <span>
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M5 12l14 0" /><path d="M13 18l6 -6" /><path d="M13 6l6 6" /></svg>
                            </span>
                        </div>
                    </Card>
                </div>
                <div className="md:col-span-3">
                    <div className="flex flex-col gap-3 md:gap-8">
                        <Card className="rounded-xl px-5 md:px-8 py-8 bg-gradient-to-tr from-cyan-500 to-blue-500 text-white">
                            <h5 className="text-lg font-bold">IDENTITAS</h5>
                            <p className="mt-4 md:w-[50%]">
                                Temukan lebih lanjut tentang kami dan layanan yang kami tawarkan di website profil perusahaan kami
                            </p>
                            <div className="mt-3 flex gap-1 items-center">
                                <span className="text-base font-medium">Baca Selenkapnya</span>
                                <span>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M5 12l14 0" /><path d="M13 18l6 -6" /><path d="M13 6l6 6" /></svg>
                                </span>
                            </div>
                        </Card>
                        <Card className="rounded-xl px-5 md:px-8 py-8 bg-gradient-to-br from-cyan-500 to-blue-500 text-white">
                            <h5 className="text-lg font-bold">PORTOFOLIO</h5>
                            <p className="mt-4 md:w-[50%]">
                                Dapatkan inspirasi dan lihat karya-karya terbaik dalam portfolio kami
                            </p>
                            <div className="mt-3 flex gap-1 items-center">
                                <span className="text-base font-medium">Baca Selenkapnya</span>
                                <span>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M5 12l14 0" /><path d="M13 18l6 -6" /><path d="M13 6l6 6" /></svg>
                                </span>
                            </div>
                        </Card>
                    </div>
                </div>
            </div>
            <div className="bg-gradient-to-l from-cyan-500 to-blue-500 mt-10 md:mt-16 rounded-xl md:py-3 md:pl-5">
                <div className="md:grid md:grid-cols-5 md:gap-8">
                    <div className="md:col-span-1 flex justify-center items-center">
                        <div className="text-white p-5 text-center md:text-start">
                            <span className="text-xl font-bold">Harga Tipe Website</span>
                            <p>
                                Temukan jenis website yang ingin anda buat
                            </p>
                            <div className="flex justify-center md:justify-start mt-5">
                                <Button className="bg-white text-black py-[0.6rem]">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="text-blue-500" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M13 5h8" /><path d="M13 9h5" /><path d="M13 15h8" /><path d="M13 19h5" /><path d="M3 4m0 1a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v4a1 1 0 0 1 -1 1h-4a1 1 0 0 1 -1 -1z" /><path d="M3 14m0 1a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v4a1 1 0 0 1 -1 1h-4a1 1 0 0 1 -1 -1z" /></svg>
                                    <span className="text-blue-500">Lihat Semua Harga</span>
                                </Button>
                            </div>
                        </div>
                    </div>
                    <div className="md:col-span-4">
                        <div className="flex gap-3 overflow-x-auto scrollbar-hidden p-5">
                            <div className="shadow-all rounded-xl overflow-hidden min-w-[15.1rem]">
                                <div className="bg-blue-50 p-3">
                                    <div className="text-center">
                                        <span className="font-bold text-base text-blue-500">E-COMMERCE</span>
                                    </div>
                                </div>
                                <div className="bg-white p-5">
                                    <div className="flex justify-center">
                                        <div>
                                            <span className="text-slate-600">Website Jual Beli Online</span>
                                            <div className="flex flex-col mt-3">
                                                <div className="flex gap-3">
                                                    <del className="font-semibold text-red-500">Rp. 250.000</del>
                                                    <div className="bg-yellow-700 text-white rounded-full px-3"><small>30% Diskon</small></div>
                                                </div>
                                                <div className="flex gap-1 mt-1">
                                                    <h5 className="font-semibold text-base">Rp. 199.000</h5>
                                                    <h5 className="font-semibold text-base text-slate-500">/ Bln</h5>
                                                </div>
                                            </div>
                                            <div className="mt-3">
                                                <ul>
                                                    <li className="py-[0.1rem]">
                                                        <div className="flex gap-2">
                                                            <svg xmlns="http://www.w3.org/2000/svg" className="text-blue-500" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M5 12l5 5l10 -10" /></svg>
                                                            <span>Gratis Nama Domain</span>
                                                        </div>
                                                    </li>
                                                    <li className="py-[0.1rem]">
                                                        <div className="flex gap-2">
                                                            <svg xmlns="http://www.w3.org/2000/svg" className="text-blue-500" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M5 12l5 5l10 -10" /></svg>
                                                            <span>Gratis Desain Antarmuka</span>
                                                        </div>
                                                    </li>
                                                    <li className="py-[0.1rem]">
                                                        <div className="flex gap-2">
                                                            <svg xmlns="http://www.w3.org/2000/svg" className="text-blue-500" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M5 12l5 5l10 -10" /></svg>
                                                            <span>Keamanan</span>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="mt-4">
                                                <Button className="bg-blue-500 text-white py-[0.5rem]">Daftar Sekarang</Button>
                                                <div className="text-center mt-2">
                                                    <span className="text-blue-500 font-medium cursor-pointer" onClick={() => navigation('/detail')}>Lihat Selengkapnya</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="shadow-all rounded-xl overflow-hidden min-w-[15.1rem]">
                                <div className="bg-blue-50 p-3">
                                    <div className="text-center">
                                        <span className="font-bold text-base text-blue-500">E-COMMERCE</span>
                                    </div>
                                </div>
                                <div className="bg-white p-5">
                                    <div className="flex justify-center">
                                        <div>
                                            <span className="text-slate-600">Website Jual Beli Online</span>
                                            <div className="flex flex-col mt-3">
                                                <div className="flex gap-3">
                                                    <del className="font-semibold text-red-500">Rp. 250.000</del>
                                                    <div className="bg-yellow-700 text-white rounded-full px-3"><small>30% Diskon</small></div>
                                                </div>
                                                <div className="flex gap-1 mt-1">
                                                    <h5 className="font-semibold text-base">Rp. 199.000</h5>
                                                    <h5 className="font-semibold text-base text-slate-500">/ Bln</h5>
                                                </div>
                                            </div>
                                            <div className="mt-3">
                                                <ul>
                                                    <li className="py-[0.1rem]">
                                                        <div className="flex gap-2">
                                                            <svg xmlns="http://www.w3.org/2000/svg" className="text-blue-500" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M5 12l5 5l10 -10" /></svg>
                                                            <span>Gratis Nama Domain</span>
                                                        </div>
                                                    </li>
                                                    <li className="py-[0.1rem]">
                                                        <div className="flex gap-2">
                                                            <svg xmlns="http://www.w3.org/2000/svg" className="text-blue-500" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M5 12l5 5l10 -10" /></svg>
                                                            <span>Gratis Desain Antarmuka</span>
                                                        </div>
                                                    </li>
                                                    <li className="py-[0.1rem]">
                                                        <div className="flex gap-2">
                                                            <svg xmlns="http://www.w3.org/2000/svg" className="text-blue-500" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M5 12l5 5l10 -10" /></svg>
                                                            <span>Keamanan</span>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="mt-4">
                                                <Button className="bg-blue-500 text-white py-[0.5rem]">Daftar Sekarang</Button>
                                                <div className="text-center mt-2">
                                                    <span className="text-blue-500 font-medium cursor-pointer" onClick={() => navigation('/detail')}>Lihat Selengkapnya</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="shadow-all rounded-xl overflow-hidden min-w-[15.1rem]">
                                <div className="bg-blue-50 p-3">
                                    <div className="text-center">
                                        <span className="font-bold text-base text-blue-500">E-COMMERCE</span>
                                    </div>
                                </div>
                                <div className="bg-white p-5">
                                    <div className="flex justify-center">
                                        <div>
                                            <span className="text-slate-600">Website Jual Beli Online</span>
                                            <div className="flex flex-col mt-3">
                                                <div className="flex gap-3">
                                                    <del className="font-semibold text-red-500">Rp. 250.000</del>
                                                    <div className="bg-yellow-700 text-white rounded-full px-3"><small>30% Diskon</small></div>
                                                </div>
                                                <div className="flex gap-1 mt-1">
                                                    <h5 className="font-semibold text-base">Rp. 199.000</h5>
                                                    <h5 className="font-semibold text-base text-slate-500">/ Bln</h5>
                                                </div>
                                            </div>
                                            <div className="mt-3">
                                                <ul>
                                                    <li className="py-[0.1rem]">
                                                        <div className="flex gap-2">
                                                            <svg xmlns="http://www.w3.org/2000/svg" className="text-blue-500" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M5 12l5 5l10 -10" /></svg>
                                                            <span>Gratis Nama Domain</span>
                                                        </div>
                                                    </li>
                                                    <li className="py-[0.1rem]">
                                                        <div className="flex gap-2">
                                                            <svg xmlns="http://www.w3.org/2000/svg" className="text-blue-500" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M5 12l5 5l10 -10" /></svg>
                                                            <span>Gratis Desain Antarmuka</span>
                                                        </div>
                                                    </li>
                                                    <li className="py-[0.1rem]">
                                                        <div className="flex gap-2">
                                                            <svg xmlns="http://www.w3.org/2000/svg" className="text-blue-500" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M5 12l5 5l10 -10" /></svg>
                                                            <span>Keamanan</span>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="mt-4">
                                                <Button className="bg-blue-500 text-white py-[0.5rem]">Daftar Sekarang</Button>
                                                <div className="text-center mt-2">
                                                    <span className="text-blue-500 font-medium cursor-pointer" onClick={() => navigation('/detail')}>Lihat Selengkapnya</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="shadow-all rounded-xl overflow-hidden min-w-[15.1rem]">
                                <div className="bg-blue-50 p-3">
                                    <div className="text-center">
                                        <span className="font-bold text-base text-blue-500">E-COMMERCE</span>
                                    </div>
                                </div>
                                <div className="bg-white p-5">
                                    <div className="flex justify-center">
                                        <div>
                                            <span className="text-slate-600">Website Jual Beli Online</span>
                                            <div className="flex flex-col mt-3">
                                                <div className="flex gap-3">
                                                    <del className="font-semibold text-red-500">Rp. 250.000</del>
                                                    <div className="bg-yellow-700 text-white rounded-full px-3"><small>30% Diskon</small></div>
                                                </div>
                                                <div className="flex gap-1 mt-1">
                                                    <h5 className="font-semibold text-base">Rp. 199.000</h5>
                                                    <h5 className="font-semibold text-base text-slate-500">/ Bln</h5>
                                                </div>
                                            </div>
                                            <div className="mt-3">
                                                <ul>
                                                    <li className="py-[0.1rem]">
                                                        <div className="flex gap-2">
                                                            <svg xmlns="http://www.w3.org/2000/svg" className="text-blue-500" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M5 12l5 5l10 -10" /></svg>
                                                            <span>Gratis Nama Domain</span>
                                                        </div>
                                                    </li>
                                                    <li className="py-[0.1rem]">
                                                        <div className="flex gap-2">
                                                            <svg xmlns="http://www.w3.org/2000/svg" className="text-blue-500" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M5 12l5 5l10 -10" /></svg>
                                                            <span>Gratis Desain Antarmuka</span>
                                                        </div>
                                                    </li>
                                                    <li className="py-[0.1rem]">
                                                        <div className="flex gap-2">
                                                            <svg xmlns="http://www.w3.org/2000/svg" className="text-blue-500" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M5 12l5 5l10 -10" /></svg>
                                                            <span>Keamanan</span>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="mt-4">
                                                <Button className="bg-blue-500 text-white py-[0.5rem]">Daftar Sekarang</Button>
                                                <div className="text-center mt-2">
                                                    <span className="text-blue-500 font-medium cursor-pointer" onClick={() => navigation('/detail')}>Lihat Selengkapnya</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="py-10 md:py-20">
                <div className="text-center">
                    <h1 className="font-bold text-blue-900 text-2xl md:text-3xl">Butuh Jawaban?</h1>
                </div>
                <ul className="mt-5">
                    <li className="border-b py-4 md:py-5">
                        <div className="flex justify-between items-center gap-5">
                            <span className="text-sm md:text-lg">Bagaimana cara saya mendaftar di situs e-commerce ini?</span>
                            <span className="bg-blue-50 p-1 rounded-full">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M6 9l6 6l6 -6" /></svg>
                            </span>
                        </div>
                    </li>
                    <li className="border-b py-4 md:py-5">
                        <div className="flex justify-between items-center gap-5">
                            <span className="text-sm md:text-lg">Apakah ada biaya pendaftaran yang harus saya bayar?</span>
                            <span className="bg-blue-50 p-1 rounded-full">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M6 9l6 6l6 -6" /></svg>
                            </span>
                        </div>
                    </li>
                    <li className="border-b py-4 md:py-5">
                        <div className="flex justify-between items-center gap-5">
                            <span className="text-sm md:text-lg">Apakah saya perlu membuat akun untuk berbelanja di situs ini?</span>
                            <span className="bg-blue-50 p-1 rounded-full">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M6 9l6 6l6 -6" /></svg>
                            </span>
                        </div>
                    </li>
                    <li className="border-b py-4 md:py-5">
                        <div className="flex justify-between items-center gap-5">
                            <span className="text-sm md:text-lg">Apakah ada persyaratan usia minimum untuk mendaftar?</span>
                            <span className="bg-blue-50 p-1 rounded-full">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M6 9l6 6l6 -6" /></svg>
                            </span>
                        </div>
                    </li>
                    <li className="border-b py-4 md:py-5">
                        <div className="flex justify-between items-center gap-5">
                            <span className="text-sm md:text-lg">Apakah informasi pribadi saya akan aman dan tidak disalahgunakan?</span>
                            <span className="bg-blue-50 p-1 rounded-full">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M6 9l6 6l6 -6" /></svg>
                            </span>
                        </div>
                    </li>
                </ul>
            </div>
        </Content>
    );
}

export default MainPage;