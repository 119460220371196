import NavbarWithBack from "../navbar/NavbarWithBack";

const ContentBlank = (props) => {

    return (
        <div className="text-[13px] flex flex-col min-h-screen">
            {props.navbar !== false && (<NavbarWithBack title="Kembali" is_active={props.is_active} />)}
            <div className="grow relative flex justify-center">
                <div className={`${props.padding !== false && 'px-3'} pb-16 relative w-full md:w-[95%] max-w-[80rem]`}>
                    {props.children}
                </div>
            </div>
        </div>
    );
}

export default ContentBlank;