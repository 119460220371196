import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { logo } from "../../../../assets";
import MenuProfileModal from "../modal/MenuProfileModal";

const Navbar = (props) => {
    const navigation = useNavigate();
    const [element, setElement] = useState();

    const onSetElement = () => {
        setElement(<MenuProfileModal onMouseOver={() => onSetElement()} onMouseLeave={() => onEmptyElement()} />);
    }

    const onEmptyElement = () => {
        setElement(undefined);
    }

    return (
        <nav className="bg-white py-1 px-7 shadow-md z-10 flex justify-between items-center">
            <div className="flex items-center gap-2">
                <img src={logo} alt="logoPTRelasiSymbioSolusi" className="w-10 p-1" />
                <div className="flex flex-col leading-3">
                    <span className="font-medium text-[10px]">PT Relasi</span>
                    <span className="font-medium text-[10px]">Symbio</span>
                    <span className="font-medium text-[10px]">Solusi</span>
                </div>
            </div>
            <div className="flex items-center gap-3 cursor-pointer" onClick={() => navigation('/profile')} onMouseOver={() => onSetElement()} onMouseLeave={() => onEmptyElement()}>
                <span className="font-semibold">{'Anonim'}</span>
                <div className="w-8 h-8 rounded-full bg-slate-200"></div>
            </div>
            {element}
        </nav>
    );
}

export default Navbar;