import { logo } from "../../../assets";
import { Button, ContentBlank, InputCheck, InputEmail, InputPassword, InputText } from "../../../components/landing";
import { UseRegisterContext } from "../../../contexts/auth/register/RegisterContext";

const RegisterPage = () => {
    const { navigation } = UseRegisterContext();

    return (
        <ContentBlank>
            <div className="py-20 flex flex-wrap-reverse justify-center gap-5">
                <div>
                    <div className="leading-3">
                        <h2 className="text-lg font-bold">Ayo, Daftar Sekarang</h2>
                        <small>Ciptakan peluang <b>Bisnismu</b> dengan <b>Kreativitasmu</b></small>
                    </div>
                    <div className="mt-5">
                        <label className="font-medium">Nama Lengkap</label>
                        <InputText />
                    </div>
                    <div className="mt-2">
                        <label className="font-medium">E-Mail</label>
                        <InputEmail />
                    </div>
                    <div className="mt-2">
                        <label className="font-medium">Kata Sandi</label>
                        <InputPassword />
                    </div>
                    <div className="mt-3 flex items-start gap-2">
                        <div className="mt-1">
                            <InputCheck />
                        </div>
                        <div className="w-96">
                            <span>Saya setuju dan mengikuti <span className="font-bold text-blue-600 cursor-pointer">Persyaratan</span> dan <span className="font-bold text-blue-600 cursor-pointer whitespace-pre">Kebijakan Privasi</span> yang ada</span>
                        </div>
                    </div>
                    <div className="pt-5">
                        <Button className="bg-blue-500 text-white py-[0.5rem]">Daftar</Button>
                    </div>
                    <div className="mt-2 flex gap-1 justify-center">
                        <span>Sudah punya akun?</span>
                        <span className="font-medium text-blue-500 cursor-pointer" onClick={() => navigation('/login')}>Masuk</span>
                    </div>
                </div>
                <div className="w-96 bg-blue-200 rounded-lg py-10 px-5">
                    <div className="flex flex-col">
                        <div className="flex justify-center">
                            <div className="w-14 h-14">
                                <img src={logo} alt="LogoPtRelasiSymbioSolusi" className="w-full" />
                            </div>
                        </div>
                        <div className="mt-5 text-center">
                            <span className="whitespace-pre font-bold uppercase">Relasi Symbio Solusi</span>
                        </div>
                        <div className="mt-4 text-center">
                            <span className="whitespace-pre font-medium">Selamat datang di dunia <br />Kreativitas dan Kemudahan!</span>
                        </div>
                    </div>
                </div>
            </div>
        </ContentBlank>
    );
}

export default RegisterPage;