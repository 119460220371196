import { useNavigate } from "react-router-dom";

const Sidebar = () => {
    const navigation = useNavigate();
    return (
        <aside className="relative w-[13rem] w-min-[13rem] w-max-[13rem] border-e bg-white">
            <div className="px-2 py-3 mb-3 border-y cursor-pointer">
                <div className="flex gap-2 items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                        <path d="M15 6l-6 6l6 6"></path>
                    </svg>
                    <span className="font-medium">Sembunyikan Menu</span>
                </div>
            </div>
            <ul>
                <li className="group hover:bg-blue-100 hover:text-blue-500 cursor-pointer flex gap-2 items-center" onClick={() => navigation('/board')}>
                    <div className="w-[5px] rounded-e-md group-hover:bg-blue-500 py-4" />
                    <div className="flex gap-2">
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M5 12l-2 0l9 -9l9 9l-2 0" /><path d="M5 12v7a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-7" /><path d="M9 21v-6a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v6" /></svg>
                        <span className="font-semibold">Dasbor</span>
                    </div>
                </li>
                <li className="group hover:bg-blue-100 hover:text-blue-500 cursor-pointer flex gap-2 items-center" onClick={() => navigation('/board')}>
                    <div className="w-[5px] rounded-e-md group-hover:bg-blue-500 py-4" />
                    <div className="flex gap-2">
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M13 5h8" /><path d="M13 9h5" /><path d="M13 15h8" /><path d="M13 19h5" /><path d="M3 4m0 1a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v4a1 1 0 0 1 -1 1h-4a1 1 0 0 1 -1 -1z" /><path d="M3 14m0 1a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v4a1 1 0 0 1 -1 1h-4a1 1 0 0 1 -1 -1z" /></svg>
                        <span className="font-semibold">Produk</span>
                    </div>
                </li>
                <li className="group ms-3 mt-1"><small>Transaksi</small></li>
                <li className="group hover:bg-blue-100 hover:text-blue-500 cursor-pointer flex gap-2 items-center" onClick={() => navigation('/board')}>
                    <div className="w-[5px] rounded-e-md group-hover:bg-blue-500 py-4" />
                    <div className="flex gap-2">
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M11 6h9" /><path d="M11 12h9" /><path d="M12 18h8" /><path d="M4 16a2 2 0 1 1 4 0c0 .591 -.5 1 -1 1.5l-3 2.5h4" /><path d="M6 10v-6l-2 2" /></svg>
                        <span className="font-semibold">Pelanggan</span>
                    </div>
                </li>
            </ul>
        </aside>
    );
}

export default Sidebar;